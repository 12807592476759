import React from "react";
import styled from "styled-components";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";

function TermsPage() {
  const { dimension, rwd } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;

  return (
    <Wrapper isPad={pad} isMobile={mobile} rwd={rwd}>
      <div className="content">
        <h1>服務條款聲明</h1>
        <p>
          <span>
            感官文化印刷 Sensations Print
            電子商務網站（以下稱本網站）係由感官文化印刷有限公司（以下簡稱本公司）建置與提供，依據本服務使用條款（以下簡稱本條款），
          </span>
        </p>
        <p>
          <span>
            提供商品印刷及銷售服務之網站。當您使用本網進行交易時，即表示您已閱讀、瞭解並同意接受本條款（構成雙方間之合意及契約）之所有內容。
          </span>
        </p>
        <p>
          <span>
            本公司有權於任何時間修改或變更本契約書之內容，建議您隨時注意該等修改或變更。本網站保有審核、終止承攬與解釋條約之權利。
          </span>
        </p>
        <p>
          <span>
            當您使用本網站訂購交易之始，即表示您同意本網站問答中心所詳列之條款與須知，並瞭解並同意接受該等修改或變更。為維護您的權益，
          </span>
        </p>
        <p>
          <span>
            下單前請務必詳細閱讀本網站問答中心所載條款與須知。本網站保有審核、終止承攬與解釋條約之權利。
          </span>
        </p>
        <p>
          <HighLight>
            為保障您的權益，務必請詳閱下列服務使用條款內容：
          </HighLight>
        </p>
        <h2>
          <span>一、認知與接受條款</span>
        </h2>
        <p>
          <span>
            若您不同意本條款內容之全部或一部，或者您所屬的國家或地域排除本條款內容之全部或一部時，請您立即停止使用本網服務。
          </span>
        </p>
        <p>
          <span>
            為維護您的權益，下單前請務必詳細閱讀本網站問答中心所載條款與須知。
          </span>
        </p>
        <h2>
          <span>二、您的註冊義務</span>
        </h2>
        <p>
          <span>
            為了能使用本網服務，並協助本公司提供完善產品服務，您同意以下事項：
          </span>
        </p>
        <p>
          <span>
            (1)
            依本網服務註冊表之提示同意提供您本人正確、最新及完整的資料予本公司作為客戶資料管理之用。
          </span>
        </p>
        <p>
          <span>
            (2)
            維持並更新您個人資料，確保其為正確、最新及完整。若您提供任何錯誤、不實或不完整的資料，
          </span>
        </p>
        <p>
          <span>
            {" "}
            本公司有權暫停使用本網站訂購服務或終止您的帳號，並拒絕您使用本網服務之全部或一部。
          </span>
        </p>
        <h2>
          <span>三、隱私權政策</span>
        </h2>
        <p>
          <span>
            關於您的會員註冊以及其他特定資料依本公司「隱私權政策」受到保護與規範。
          </span>
        </p>
        <p>
          <span>
            您了解當您使用本網服務時，您同意本公司依據「隱私權政策」進行您個人資料的蒐集與利用，及本公司及其關係企業內部之利用。
          </span>
        </p>
        <h2>
          <span>四、會員帳號、密碼及安全</span>
        </h2>
        <p>
          <span>
            完成本網服務的加入會員程序之後，您將會有一組密碼及帳號。維持密碼及帳號的機密安全，是您的責任。您並同意以下事項：
          </span>
        </p>
        <p>
          <span>
            (1)
            您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您應立即通知本公司。
          </span>
        </p>
        <p>
          <span>(2) 每次連線完畢，均登出以結束您的帳號使用。</span>
        </p>
        <h2>
          <span>五、同意本網站問答中心FAQ印刷須知</span>
        </h2>
        <p>
          <span>
            為維護您的權益，下單前請務必詳細閱讀，本網站問答中心FAQ『印刷須知』中所載條款與須知。
          </span>
        </p>
        <p>
          <span>
            當您使用本網站訂購交易之始，即表示您同意本網站問答中心FAQ『印刷須知』項目中，所詳列之條款與須知。
          </span>
        </p>
        <p>
          <span>
            若不同意印刷須知，相關之委印須知及條款
            、完稿說明、瑕疵判別及正常範圍說明
            ，或不接受本站服務條款的其他任一約定，會員應立即停止使用本站服務。
          </span>
        </p>
        <h2>
          <span>六、使用者的守法義務及承諾</span>
        </h2>
        <p>
          <span>
            您承諾絕不為任何非法目的或以任何非法方式使用本網服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。
          </span>
        </p>
        <p>
          <span>
            您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本網服務從事侵害他人權益或違法之行為，包括但不限於：
          </span>
        </p>
        <p>
          <span>
            (1)
            製作任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本網服務上。
          </span>
        </p>
        <p>
          <span>
            (2)
            侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。
          </span>
        </p>
        <p>
          <span>
            (3)
            使用目前已發行或流通貨幣樣式或/及予以增、刪、修改、變造、偽造或自行加註用字發印予本公司者（本公司得不接受承製），所有法律責任由製稿人或該圖樣之所有人負責。
          </span>
        </p>
        <p>
          <span>(4) 違反依法律或契約所應負之保密義務。</span>
        </p>
        <p>
          <span>(5) 冒用他人名義使用本網服務。</span>
        </p>
        <p>
          <span>
            (6)
            以任何方法傷害未成年人。謹慎選擇合適網站供兒童及青少年瀏覽。未滿十二歲之兒童上網時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年上網前亦應斟酌是否給予同意。
          </span>
        </p>
        <p>
          <span>(7) 偽造訊息來源或以任何方式干擾傳輸來源之認定。</span>
        </p>
        <p>
          <span>
            (8)
            干擾或中斷本網服務或伺服器或連結本網服務之網路，或不遵守連結至本網服務之相關需求、程序、政策或規則等。
          </span>
        </p>
        <p>
          <span>(9) 對於恐怖行動提供任何實質支持或資源。</span>
        </p>
        <p>
          <span>
            (10) 追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊。
          </span>
        </p>
        <p>
          <span>(11) 其他本公司有正當理由認為不適當之行為。</span>
        </p>
        <p>
          <span>
            (12)
            為確保兒童及青少年使用網路的安全，並避免隱私權受到侵犯，先檢閱各該網站是否有保護個人資料的隱私權政策，
          </span>
        </p>
        <p>
          <span>
            {" "}
            並應持續叮嚀兒童及青少年不可洩漏自己或家人的任何個人資料（包括但不限於姓名、地址、電話、電子郵件信箱、照片、信用卡號等）給任何人。
          </span>
        </p>
        <h2>
          <span>七、系統中斷或故障</span>
        </h2>
        <p>
          <span>
            本網服務有時可能會出現中斷或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。
          </span>
        </p>
        <p>
          <span>
            您於使用本網服務時宜自行採取防護措施。本公司對於您因使用（或無法使用）本網服務而造成的損害，不負任何賠償責任。
          </span>
        </p>
        <h2>
          <span>八、交易行為</span>
        </h2>
        <p>
          <span>
            會員同意其訂購行為，以本網站所示之電子交易資料為準，如有任何糾紛，將以該電子交易資料為認定標準。
          </span>
        </p>
        <p>
          <span>
            如您因於本網訂購商品，致本公司對您負有包含但不限於債務不履行、侵權行為之損害賠償責任時，您同意以該筆訂單之交易總額作為本公司損害賠償責任之上限。
          </span>
        </p>
        <h2>
          <span>九、智慧財產權的保護</span>
        </h2>
        <p>
          <span>
            本網所使用之軟體或程式、網站上所有內容，均由本網或其他權利人依法擁有其智慧財產權。
          </span>
        </p>
        <p>
          <span>
            任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
          </span>
        </p>
        <p>
          <span>
            尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負所有損害賠償責任。
          </span>
        </p>
        <p>
          <span>&#xFEFF;</span>
        </p>
        <h2>
          <span>十、您對本公司之授權</span>
        </h2>
        <p>
          <span>
            (1)
            您同意授權本公司及本網站，得為提供服務及贈獎之目的，提供必需之會員個人資料給本公司做約定範圍內之妥善使用。
          </span>
        </p>
        <p>
          <span>
            {" "}
            對於您所登入之個人資料，您同意本公司得於合理之範圍內蒐集、處理、保存、傳遞及利用該等資料，
          </span>
        </p>
        <p>
          <span>
            {" "}
            以提供您其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
          </span>
        </p>
        <p>
          <span>
            (2)
            另外，若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表所提供之任何資料，
          </span>
        </p>
        <p>
          <span>
            {" "}
            並將前述權利轉授權第三人，
            請勿擅自將該資料上載、傳送、輸入或提供予本網。
          </span>
        </p>
        <h2>
          <span>十一、服務變更</span>
        </h2>
        <p>
          <span>
            本公司保留於任何時點，不經通知隨時修改或暫時或永久停止、變更本網服務（或其任一部分）的權利，建議您隨時注意該修改、停止或變更。
          </span>
        </p>
        <p>
          <span>
            您同意本公司不須對於任何上開所述修改、停止或變更本網服務所造成您或第三人之不便或損失負任何責任。
          </span>
        </p>
        <p>
          <span>
            您同意本公司於您或是任何第三方對於任何修改、暫停或停止繼續提供本服務負任何責任。
          </span>
        </p>
        <h2>
          <span>十二、拒絕或終止您的使用</span>
        </h2>
        <p>
          <span>
            您同意本公司得因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、您自行請求、本網服務無法繼續或服務內容實質變更、無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他本公司認為您已經違反本條款的明文規定及精神，而終止您的密碼、帳號（或其任何部分）或使用本網服務，並將本網服務內任何「會員內容」刪除。
          </span>
        </p>
        <p>
          <span>
            您亦同意本公司無需進行事先通知即得因任何理由隨時終止本網服務之一部或全部，並刪除您的帳號及您帳號中所有相關資料及檔案。
          </span>
        </p>
        <p>
          <span>
            此外，若本網服務因任何原因終止，您同意本公司對您或第三人均不承擔責任。
          </span>
        </p>
        <h2>
          <span>十三、一般條款</span>
        </h2>
        <p>
          <span>
            本條款之解釋與適用，以及與本條款有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理。因
          </span>
        </p>
        <p>
          <span>
            本條款有關的爭議，除符合民事訴訟法第436條之9規定外，您及本公司均同意以台灣台北地方法院為第一審管轄法院。
          </span>
        </p>
        <p>
          <span>
            本公司如未行使或執行本條款任何權利或規定，並非拋棄該等權利。若任何本條款部份規定，經法院認定無效者，本條款之其他規定仍完全有效。
          </span>
        </p>
        <p style={{ "margin-top": "40px" }}>
          <span>── 感官文化印刷有限公司</span>
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding: ${({ isMobile }) => (isMobile ? "20px 0" : "44px 0")};
  & .content {
    margin: 0 auto;
    position: relative;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 1.56;
    max-width: ${({ isMobile, isPad }) => {
      if (isPad || isMobile) return "90%";
      return Theme.centerContentMaxWidth;
    }};
  }
  p {
    margin: 0px;
    font-size: ${({ isMobile }) => (isMobile ? "14px" : "16px")};
  }

  h1 {
    text-align: center;
    font-size: ${({ isMobile }) => (isMobile ? "24px" : "32px")};
  }

  h2 {
    margin-top: 2em;
    font-size: ${({ isMobile }) => (isMobile ? "20px" : "24px")};

    span {
      /* Add styles for h2 span if needed */
    }
  }
`;

const HighLight = styled.span`
  background-color: yellow;
`;

export default TermsPage;
